import dynamic from 'next/dynamic'

const LoadingComponent = dynamic(() => import('../src/pageComponents/Loading'), { ssr: false })

export default function Loading() {
    // You can add any UI inside Loading, including a Skeleton.
    return (
        <main className="bg-white w-screen h-screen">
            <LoadingComponent />
        </main>
    );
}